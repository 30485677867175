export default {
    empty: "Nothing to approve",
    saved: "Approvals submitted",
    review: "Please review and edit cost center if incorrect",
    confirm: {
        partial: "There are still orders with pending approvals. Do you wish to continue with your current changes?"
    },
    header: {
        name: "Name",
        order: "Order",
        details: "Details",
        cta: "Approval"
    },
    item: {
        order: {
            manager: "Manager",
            requested: "Requested by"
        },
        approve: {
            reason: {
                placeholder: {
                    default: "reason",
                    approved: "reason",
                    notapproved: "reason"
                }
            },
            approve: {
                yes: {
                    label: "Yes"
                },
                no: {
                    label: "No"
                }
            }
        },
        prior: {
            cta: {
                load: "Prior approvals",
                loading: "Loading",
                show: "Prior approvals",
                hide: "Prior approvals"
            },
            approvals: {
                empty: "No prior approvals",
                item: {
                    status: {
                        approver: "by {approver}",
                        system: "Help Desk",
                        approved: "Approved",
                        rejected: "Not Approved",
                        unrequired: "Approval Not Required",
                        reason: "Reason",
                        transferred: "Transferred"
                    }
                }
            }
        }
    },
    cta: {
        submit: {
            text: "Submit",
            processing: "Submitting"
        }
    }
}