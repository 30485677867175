export default {
    empty: "Rien à approuver",
    saved: "Approbations soumises",
    review: "Veuillez vérifier et modifier le centre de coûts s'il est incorrect.",
    confirm: {
        partial: "Il reste des commandes en attente d'approbation. Souhaitez-vous poursuivre vos modifications actuelles ?"
    },
    header: {
        name: "Nom",
        order: "Commande",
        details: "Détails",
        cta: "Approbation"
    },
    item: {
        order: {
            manager: "Directeur",
            requested: "Demandé par"
        },
        approve: {
            reason: {
                placeholder: {
                    default: "raison",
                    approved: "raison",
                    notapproved: "raison"
                }
            },
            approve: {
                yes: {
                    label: "Oui"
                },
                no: {
                    label: "Non"
                }
            }
        },
        prior: {
            cta: {
                load: "Approbations préalables",
                loading: "Chargement",
                show: "Approbations préalables",
                hide: "Approbations préalables"
            },
            approvals: {
                empty: "Aucune approbation préalable",
                item: {
                    status: {
                        approver: "par {approver}",
                        system: "Service d'assistance",
                        approved: "Approuvé",
                        rejected: "Non approuvé",
                        unrequired: "Approbation non requise",
                        reason: "Raison",
                        transferred: "Transféré"
                    }
                }
            }
        }
    },
    cta: {
        submit: {
            text: "Soumettre",
            processing: "Soumission"
        }
    }
}