export default {
    empty: "Nic do zatwierdzenia",
    saved: "Złożone zatwierdzenia",
    review: "Proszę sprawdzić i edytować centrum kosztów, jeśli jest nieprawidłowe",
    confirm: {
        partial: "Nadal są zamówienia z oczekującymi zatwierdzeniami. Czy chcesz kontynuować obecne zmiany?"
    },
    header: {
        name: "Nazwa",
        order: "Zamówienie",
        details: "Bliższe dane",
        cta: "Aprobata"
    },
    item: {
        order: {
            manager: "Menedżer",
            requested: "Na prośbę"
        },
        approve: {
            reason: {
                placeholder: {
                    default: "powód",
                    approved: "powód",
                    notapproved: "powód"
                }
            },
            approve: {
                yes: {
                    label: "Tak"
                },
                no: {
                    label: "NIE"
                }
            }
        },
        prior: {
            cta: {
                load: "Wcześniejsze zgody",
                loading: "Załadunek",
                show: "Wcześniejsze zgody",
                hide: "Wcześniejsze zgody"
            },
            approvals: {
                empty: "Brak wcześniejszych zgód",
                item: {
                    status: {
                        approver: "przez {approver}",
                        system: "Biuro pomocy",
                        approved: "Zatwierdzony",
                        rejected: "Niezatwierdzone",
                        unrequired: "Zatwierdzenie nie jest wymagane",
                        reason: "Powód",
                        transferred: "Przeniesiony"
                    }
                }
            }
        }
    },
    cta: {
        submit: {
            text: "Składać",
            processing: "Przedkładający"
        }
    }
}