export default {
    empty: "沒有需要批准的",
    saved: "已提交批准",
    review: "如果不正确，请检查并编辑成本中心",
    confirm: {
        partial: "仍有待批准的订单。是否要继续当前的更改？"
    },
    header: {
        name: "姓名",
        order: "命令",
        details: "细节",
        cta: "赞同"
    },
    item: {
        order: {
            manager: "经理",
            requested: "请求者"
        },
        approve: {
            reason: {
                placeholder: {
                    default: "原因",
                    approved: "原因",
                    notapproved: "原因"
                }
            },
            approve: {
                yes: {
                    label: "是的"
                },
                no: {
                    label: "不"
                }
            }
        },
        prior: {
            cta: {
                load: "事先批准",
                loading: "加载中",
                show: "事先批准",
                hide: "事先批准"
            },
            approvals: {
                empty: "无需事先批准",
                item: {
                    status: {
                        approver: "来自 {approver}",
                        system: "服务台",
                        approved: "得到正式认可的",
                        rejected: "未批准",
                        unrequired: "无需批准",
                        reason: "原因",
                        transferred: "已转移"
                    }
                }
            }
        }
    },
    cta: {
        submit: {
            text: "提交",
            processing: "提交"
        }
    }
}