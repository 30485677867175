export default {
    ordered: {
        type: {
            "ac_router": "已订购不同的 AC 路由器",
            "chair": "已经订购了不同的椅子",
            "default": "已下单",
            "default_multiple": "已订购类似产品",
            "desk": "已经订购了不同的办公桌",
            "desktop": "已经订购了不同的桌面",
            "desktop_riser": "已经订购了不同的桌面支架",
            "detector": "已经订购了不同的电子烟和噪音检测仪",
            "displayport_cable": "已经订购了不同的显示端口电缆",
            "docking station": "已订购不同的扩展坞",
            "dual_usb_adapter": "已订购不同的双频 USB 适配器",
            "ear cushion": "已经订购了不同的耳垫",
            "footrest": "已经订购了不同的脚凳",
            "hdmi_cable": "已经订购了不同的 HDMI 线",
            "hdmi_displayport_cable": "已订购不同的 HDMI 转 DisplayPort 线缆",
            "hdmi_vga_cable": "已订购不同的 HDMI 转 VGA 线",
            "headset": "已订购其他音频设备",
            "keyboard": "已经订购了不同的键盘",
            "kvm switch": "已经订购了不同的 kvm 切换器",
            "kvm_switch": "已经订购了不同的 kvm 切换器",
            "lamp": "已经订购了不同的灯",
            "laptop stand": "已订购不同的笔记本电脑支架",
            "laptop": "已经订购了不同的笔记本电脑",
            "mesh_router": "已订购不同的网状路由器",
            "monitor": "已经订购了不同的显示器",
            "mouse": "已经订购了不同的鼠标",
            "patch_cord": "已经订购了不同的 cat6 跳线",
            "power_center": "已订购不同的 Power Center",
            "power_strip": "已经订购了不同的电源板",
            "smart_plug": "已订购不同的迷你智能插头",
            "smart_power_strip": "已订购不同的智能电源板",
            "thunderbolt_cable": "已订购不同的 USB-C 转 Thunderbolt 线缆",
            "travel_router": "已经订购了不同的旅行路由器",
            "usb hub": "已订购不同的 USB 集线器",
            "usb_adapter": "已订购不同的 USB 适配器",
            "usb_hub": "已订购不同的集线器",
            "webcam": "已订购不同的网络摄像头"
        },
        product: "有序"
    },
    request: "要求",
    add: "添加到购物车",
    remove: "从购物车中移除",
    added: "已添加到购物车",
    view: "查看商品"
}