export default {
    empty: "Nichts zu genehmigen",
    saved: "Eingereichte Genehmigungen",
    review: "Bitte prüfen Sie die Kostenstelle und bearbeiten Sie sie, falls sie falsch ist.",
    confirm: {
        partial: "Es gibt noch Bestellungen mit ausstehenden Genehmigungen. Möchten Sie mit Ihren aktuellen Änderungen fortfahren?"
    },
    header: {
        name: "Name",
        order: "Befehl",
        details: "Details",
        cta: "Genehmigung"
    },
    item: {
        order: {
            manager: "Manager",
            requested: "Angefordert von"
        },
        approve: {
            reason: {
                placeholder: {
                    default: "Grund",
                    approved: "Grund",
                    notapproved: "Grund"
                }
            },
            approve: {
                yes: {
                    label: "Ja"
                },
                no: {
                    label: "NEIN"
                }
            }
        },
        prior: {
            cta: {
                load: "Vorherige Genehmigungen",
                loading: "Laden",
                show: "Vorherige Genehmigungen",
                hide: "Vorherige Genehmigungen"
            },
            approvals: {
                empty: "Keine vorherigen Genehmigungen",
                item: {
                    status: {
                        approver: "von {approver}",
                        system: "Hilfe-Center",
                        approved: "Genehmigt",
                        rejected: "Nicht genehmigt",
                        unrequired: "Genehmigung nicht erforderlich",
                        reason: "Grund",
                        transferred: "Übertragen"
                    }
                }
            }
        }
    },
    cta: {
        submit: {
            text: "Einreichen",
            processing: "Einreichen"
        }
    }
}