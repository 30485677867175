export default {
    empty: "Nic ke schválení",
    saved: "Schválení předloženo",
    review: "Pokud jsou nesprávné, zkontrolujte a upravte nákladové středisko",
    confirm: {
        partial: "Stále existují objednávky, které čekají na schválení. Přejete si pokračovat ve stávajících změnách?"
    },
    header: {
        name: "Jméno",
        order: "Objednávka",
        details: "Podrobnosti",
        cta: "Schválení"
    },
    item: {
        order: {
            manager: "Manažer",
            requested: "Vyžádáno uživatelem"
        },
        approve: {
            reason: {
                placeholder: {
                    default: "důvod",
                    approved: "důvod",
                    notapproved: "důvod"
                }
            },
            approve: {
                yes: {
                    label: "Ano"
                },
                no: {
                    label: "Žádný"
                }
            }
        },
        prior: {
            cta: {
                load: "Předchozí schválení",
                loading: "Načítání",
                show: "Předchozí schválení",
                hide: "Předchozí schválení"
            },
            approvals: {
                empty: "Žádná předchozí schválení",
                item: {
                    status: {
                        approver: "od {approver}",
                        system: "Help Desk",
                        approved: "Schválený",
                        rejected: "Neschváleno",
                        unrequired: "Schválení není vyžadováno",
                        reason: "Důvod",
                        transferred: "Převedeno"
                    }
                }
            }
        }
    },
    cta: {
        submit: {
            text: "Předložit",
            processing: "Odesílání"
        }
    }
}