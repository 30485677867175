export default {
    ordered: {
        type: {
            "ac_router": "Zamówiłem już inny router AC",
            "chair": "Zamówiłem już inne krzesło",
            "default": "Zamówione już",
            "default_multiple": "Zamówiłem już podobne produkty",
            "desk": "Zamówiłem już inne biurko",
            "desktop": "Zamówiłem już inny komputer stacjonarny",
            "desktop_riser": "Zamówiłem już inny podnośnik biurka",
            "detector": "Zamówiłem już różne waporyzatory i detektory hałasu",
            "displayport_cable": "Zamówiłem już inny kabel DisplayPort",
            "docking station": "Zamówiłem już inną stację dokującą",
            "dual_usb_adapter": "Zamówiłem już inny dwupasmowy adapter USB",
            "ear cushion": "Zamówiłem już inne poduszki na uszy",
            "footrest": "Zamówiłem już inny podnóżek",
            "hdmi_cable": "Zamówiłem już różne kable HDMI",
            "hdmi_displayport_cable": "Zamówiłem już inny kabel HDMI do DisplayPort",
            "hdmi_vga_cable": "Zamówiłem już inny kabel HDMI do VGA",
            "headset": "Zamówiłem już inne urządzenie audio",
            "keyboard": "Zamówiłem już inną klawiaturę",
            "kvm switch": "Zamówiłem już inny przełącznik KVM",
            "kvm_switch": "Zamówiłem już inny przełącznik KVM",
            "lamp": "Zamówiłem już inną lampę",
            "laptop stand": "Zamówiłem już inny stojak na laptopa",
            "laptop": "Zamówiłem już inny laptop",
            "mesh_router": "Zamówiłem już inny router siatkowy",
            "monitor": "Zamówiłem już inny monitor",
            "mouse": "Zamówiłem już różne myszy",
            "patch_cord": "Zamówiłem już inny kabel krosowy cat6",
            "power_center": "Zamówiłem już różne Power Center",
            "power_strip": "Zamówiłem już inną listwę zasilającą",
            "smart_plug": "Zamówiłem już różne inteligentne wtyczki mini",
            "smart_power_strip": "Zamówiłem już różne inteligentne listwy zasilające",
            "thunderbolt_cable": "Zamówiłem już inny kabel USB-C do Thunderbolt",
            "travel_router": "Zamówiłem już inny router podróżny",
            "usb hub": "Zamówiłem już inny hub USB",
            "usb_adapter": "Zamówiłem już inny adapter USB",
            "usb_hub": "Zamówiłem już inną piastę",
            "webcam": "Zamówiłem już inną kamerę internetową"
        },
        product: "Zamówione"
    },
    request: "Wniosek",
    add: "Dodaj do koszyka",
    remove: "Usuń z koszyka",
    added: "Dodano do koszyka",
    view: "Zobacz przedmiot"
}