export default {
    ordered: {
        type: {
            "ac_router": "Már rendeltem egy másik AC Routert",
            "chair": "Már rendeltem egy másik széket",
            "default": "Már megrendelték",
            "default_multiple": "Már rendeltem hasonló termékeket",
            "desk": "Már rendeltem egy másik asztalt",
            "desktop": "Már rendeltem egy másik asztali gépet",
            "desktop_riser": "Már rendeltem egy másik asztali emelőt",
            "detector": "Már rendeltem egy másik vape és zajérzékelőt",
            "displayport_cable": "Már rendeltem egy másik displayport kábelt",
            "docking station": "Már rendeltem egy másik dokkoló állomást",
            "dual_usb_adapter": "Már megrendelt egy másik kétsávos USB adaptert",
            "ear cushion": "Már rendeltem egy másik fülpárnát",
            "footrest": "Már más lábtartót rendeltem",
            "hdmi_cable": "Különféle HDMI kábeleket már rendeltem",
            "hdmi_displayport_cable": "Már rendeltem egy másik HDMI-DisplayPort kábelt",
            "hdmi_vga_cable": "Már rendeltem egy másik HDMI-VGA kábelt",
            "headset": "Már megrendelt egy másik hangeszközt",
            "keyboard": "Már rendeltem egy másik billentyűzetet",
            "kvm switch": "Már rendeltem egy másik kvm kapcsolót",
            "kvm_switch": "Más kvm kapcsolót már rendeltem",
            "lamp": "Már rendeltem egy másik lámpát",
            "laptop stand": "Már rendeltem egy másik laptop állványt",
            "laptop": "Már rendeltem egy másik laptopot",
            "mesh_router": "Már rendeltem egy másik mesh routert",
            "monitor": "Már rendeltem egy másik monitort",
            "mouse": "Már különböző egereket rendeltek",
            "patch_cord": "Már rendeltem egy másik cat6 patch zsinórt",
            "power_center": "Már rendeltem egy másik Power Centert",
            "power_strip": "Már rendeltem egy másik elosztót",
            "smart_plug": "Már rendeltem egy másik smart plug minit",
            "smart_power_strip": "Már rendeltem egy másik intelligens elosztót",
            "thunderbolt_cable": "Már rendelt egy másik USB-C-Thunderbolt kábelt",
            "travel_router": "Már rendeltem egy másik útválasztót",
            "usb hub": "Már rendeltem egy másik usb hubot",
            "usb_adapter": "Már rendeltem egy másik usb adaptert",
            "usb_hub": "Már másik hubot rendeltek",
            "webcam": "Már rendeltem egy másik webkamerát"
        },
        product: "Rendelve"
    },
    request: "Kér",
    add: "Kosárba",
    remove: "Távolítsa el a kosárból",
    added: "Hozzáadva a kosárhoz",
    view: "Elem megtekintése"
}